<template>
  <path
    d="M13.91 9.621a6.91 6.91 0 0 0-2.035-4.92L7.295.146a.469.469 0 0 0-.679 0l-4.58 4.58A6.91 6.91 0 0 0 0 9.646c0 3.66 2.86 6.69 6.47 6.932v1.938c0 .267.219.485.485.485a.486.486 0 0 0 .485-.485v-1.963a6.944 6.944 0 0 0 6.47-6.93zm-6.47 5.962v-1.72l3.078-3.079a.469.469 0 0 0 0-.678.469.469 0 0 0-.679 0l-2.399 2.4V6.397a.486.486 0 0 0-.485-.485.486.486 0 0 0-.484.485v4.483L3.247 7.682a.469.469 0 0 0-.678 0 .469.469 0 0 0 0 .679l3.902 3.902v3.344C3.393 15.365.969 12.772.969 9.645c0-1.6.63-3.102 1.745-4.216l4.241-4.266 4.217 4.217a5.922 5.922 0 0 1 1.745 4.217 5.941 5.941 0 0 1-5.477 5.986z"
    :fill="color"
    fill-rule="nonzero"
  />
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
